@import url("https://fonts.googleapis.com/css2?family=Madimi+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Madimi+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  /* --bg-color: hsl(49, 37%, 94%); */
  --type-speed: 3s;
  --border-color: rgb(217, 213, 213);
  --p-color: hsl(0 0% 0% / 0.7);
  --linkedin-color: #0c66c3;
  --bg-color: #fcfcfc;
  --ease-hover: all 0.5s ease-in-out;
}
body {
  background-color: white;
  overflow-x: hidden;
}
.App {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

section {
  padding: 40px 0;
}

.container-wrap {
  flex: 1;
}

h2,
h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.background {
  background: url("/public/images/pexels-negative-space-169573.jpg")
    rgba(0, 0, 0, 0.6);
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bio-flex {
  background: white;
  opacity: 0.9;
  padding: 50px;
  display: flex;
  place-content: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.bio-photo {
  object-fit: contain;
  width: 300px;
  height: 300px;
  border-radius: 8px;
}

.bio-description {
  margin-bottom: 20px;
}

.title {
  font-size: 50px;
  font-family: "Source Code Pro", monospace;
  position: relative;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.title::before,
.title::after {
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
}

.title::before {
  background-color: white;
  animation: typewriter var(--type-speed) steps(35) 1s forwards;
  animation-delay: 1s;
}

.title::after {
  width: 0.125em;
  background: black;
  animation: typewriter var(--type-speed) steps(35) 1s forwards,
    blinking 750ms steps(35) 1s infinite;
}

.p-developer {
  color: var(--p-color);
  font-size: 50px;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeIn 1s ease calc(var(--type-speed) + 1.5s) forwards;
}

.p-welcome {
  color: var(--p-color);
  font-size: 40px;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeIn 1s ease calc(var(--type-speed) + 3s) forwards;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.cursor {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 1.2em;
  background-color: white;
  animation: blink 0.7s infinite;
}

.typing-text::after {
  width: 0.125em;
  background: black;
  animation: typewriter var(--type-speed) steps(35) 1s forwards,
    blinking 750ms steps(35) 1s infinite;
}

/* Typing animation keyframes */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Apply typing animation to the text */
.typing-text {
  max-width: fit-content;
  color: white;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 5s steps(34) 1s 1 normal both, infinite;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blinking {
  to {
    background: transparent;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0.5;
    transform: translateX(-500px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.bio-photo,
.bio-contact,
.download-resume {
  animation: fadeInLeft 3s ease;
}

.bio-contact {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.github {
  width: 40px;
  height: 40px;
  color: var(--linkedin-color);
  margin: 10px;
  transition: var(--ease-hover);
}
.github:hover {
  color: black;
  transform: scale(1.2);
}
.linkedin {
  width: 40px;
  height: 40px;
  color: #0c66c3;
  margin: 10px;
  transition: var(--ease-hover);
}
.linkedin:hover {
  background-color: var(--linkedin-color);
  color: white;
  transform: scale(1.2);
}

.email {
  width: 40px;
  height: 40px;
  background-color: var(--linkedin-color);
  margin: 10px;
  color: white;
  border-radius: 4px;
  transition: var(--ease-hover);
}
.email:hover {
  background-color: white;
  color: var(--linkedin-color);
  transform: scale(1.2);
}

.download-resume {
  text-decoration: none;
  background-color: var(--linkedin-color);
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  transition: var(--ease-hover);
  font-size: 13px;
}

.download-resume:hover {
  color: var(--linkedin-color);
  background-color: white;
  border: 3px solid var(--linkedin-color);
}

.card-flex {
  display: flex;
  gap: 50px;
  background-color: #f9f6f6;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  padding: 40px;
  align-items: center;
  transition: 0.5s;
  margin-bottom: 20px;

  &:hover {
    opacity: 1;
    scale: 1.01;
  }
}

.project-title {
  font-size: 25px;
  font-weight: 700;
  color: var(--linkedin-color);
  text-transform: capitalize;
}

.project-img {
  height: 200px;
  background-position: center;
  background-size: cover;
}

.live-site {
  font-size: 12px;
  padding: 6px;
  background-color: var(--linkedin-color);
  color: white;
  border-radius: 8px;
  text-decoration: none;
  transition: var(--ease-hover);
}
.live-site:hover {
  background-color: white;
  color: var(--linkedin-color);
  border: 3px solid var(--linkedin-color);
}

.disabled-site {
  font-size: 12px;
  padding: 6px;
  background-color: gray;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  pointer-events: none;
  opacity: 0.5;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  text-align: center;
}

.skills h2 {
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.skill {
  width: calc((100% - 100px) / 6);
  border: 1px solid var(--linkedin-color);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  padding: 30px;
  transition: 0.5s;

  .skill-icon {
    font-size: 2rem;
    margin-bottom: 5px;
    fill: var(--linkedin-color);
    transition: 0.5s;
  }

  p {
    margin: 0;
    color: var(--linkedin-color);
  }
}

.footer {
  text-align: center;
  color: white;
  background-color: var(--linkedin-color);
}

.footer p {
  font-size: 22px;
}

.copyright {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1050px) {
  .card-flex {
    flex-direction: column;
    text-align: center;
    opacity: 1;
  }

  .project-img {
    width: 100%;
    height: auto;
  }

  .project-description {
    padding: 20px;
  }

  .bio-flex {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 1200px) {
  .title {
    font-size: 35px;
  }

  .p-developer {
    font-size: 35px;
  }

  .p-welcome {
    font-size: 35px;
  }

  .skill {
    width: calc((100% - 60px) / 4);
  }
}

@media only screen and (max-width: 790px) {
  .title {
    font-size: 30px;
  }

  .p-developer {
    font-size: 30px;
  }

  .p-welcome {
    font-size: 20px;
  }

  .skill {
    width: calc((100% - 40px) / 3);
  }
}

@media only screen and (max-width: 560px) {
  .title {
    font-size: 25px;
  }

  .p-developer {
    font-size: 25px;
  }

  .p-welcome {
    font-size: 18px;
  }

  .skill {
    width: calc((100% - 20px) / 2);
  }
}

@media only screen and (max-width: 480px) {
  .background {
    min-height: 0vh;
  }
  .title {
    font-size: 20px;
  }

  .p-developer {
    font-size: 20px;
  }

  .p-welcome {
    font-size: 16px;
  }

  .card-flex {
    gap: 5px;
  }
}
